import { ReactMic } from 'react-mic';
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner'
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import { findAllByDisplayValue } from '@testing-library/react';
import AudioSpectrum from 'react-audio-spectrum';


class ReactMicCom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      record: false,
      _blob:null,
      _blobURL:null,
      dispSpinner:false,
      _bStop:false,     
      _bPlay:false,
      _bAudioPlay:false,
      _nRecCount:1,
      _bListenTime:false,
      audioEle:null,
      
    }

    this.onData = this.onData.bind(this);
    this.onStop = this.onStop.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.audioEle1 = null;
    
    this.uploadtatus = false;
    this.recAudioUrl = "/upload/"
    this.recAudioFullUrl = null;
    this.recFileName = null;
    this.nextStaus = false;
    this.reRecStatus = false;

    this.userno = null;
    this.courseno = null;
    this.rec_file = null;
    this.qcode = null;

    

  }

  componentDidMount() {
    this.audioEle1 = document.getElementById('audio_rec')
    this.audioEle2 = document.getElementById('audio-element')
    
  }

  componentDidUpdate() {
    console.log("ComponentDidUpdate")
    const {params} = this.props.match;
    this.userno = params.key3;
    this.rec_file = params.key1;
    this.courseno = params.key2;
    this.qcode = params.key4
    
  }

  getRandomString() {
    if (window.crypto && window.crypto.getRandomValues && navigator.userAgent.indexOf('Safari') === -1) {
        var a = window.crypto.getRandomValues(new Uint32Array(3)),
            token = '';
        for (var i = 0, l = a.length; i < l; i++) {
            token += a[i].toString(36);
        }
        return token;
    } else {
        return (Math.random() * new Date().getTime()).toString(36).replace(/\./g, '');
    }
}

rndSoundFile=()=> {
  var _soundFile= null;
  var _curDate= new Date();
  var _mDate = "";
  var _mMonth = "";
  var _mHour = "";
  var _mSecond = "";
  var _mMinute = "";
  var _ymd = "";
  
  if(_curDate.getMonth() < 10)
      _mMonth = "0" + (_curDate.getMonth()+1)
  else	
      _mMonth = "" + (_curDate.getMonth()+1)
  
  if(_curDate.getDate() < 10)
      _mDate = "0" + _curDate.getDate()
  else
      _mDate = "" + _curDate.getDate()
  
  if(_curDate.getHours() < 10)
      _mHour = "0" + _curDate.getHours()
  else
      _mHour = ""	+ _curDate.getHours()
  
  if(_curDate.getMinutes() < 10)
      _mMinute = "0" + _curDate.getMinutes();
  else
      _mMinute = ""	+ _curDate.getMinutes();
  
  
  if(_curDate.getSeconds() < 10)
      _mSecond = "0" + _curDate.getSeconds()
  else
      _mSecond = ""	+ _curDate.getSeconds()
  
  
  _ymd = _curDate.getUTCFullYear() + _mMonth;		
  
  //_soundFile = "/kau/"+ _ymd + "/" + _curDate.getUTCFullYear() + _mMonth + _mDate + _mHour + _mMinute + _mSecond+"_"+this.state._uno+"_"+this.state._Qcode;
  _soundFile =  _curDate.getUTCFullYear() + _mMonth + _mDate + _mHour + _mMinute + _mSecond+"_"+this.qcode+"_"+ this.userno+"_"+this.courseno;

  console.log(_soundFile);

   
  return _soundFile
  
}

  getFileName(fileExtension) {
        
    //this.recFileName= this.rndSoundFile() + "." + fileExtension;
    this.recFileName=  this.rec_file + "." + fileExtension;

    console.log('recording file:'+this.recFileName);
    return this.recFileName
}
  startRecording = () => {  

    let self = this;
    
    if(document.audio)
      document.audio.pause();

      this.setState({ 
        _bPlay:false
      });
      
      setTimeout(function() {        
        self.setState({ record: true});        
    }, 500);

      
        
  }
 
  stopRecording=()=>{
    
    let self = this
      
    if(document.audio)
      document.audio.pause();  
    
    this.setState({ 
        _bPlay:false
      });

    setTimeout(function() {        
      self.setState({ record: false });    
    }, 500);

    
  }    

  stopPlayRecording = () => {
    if(document.audio){
      document.audio.pause();
    }

      this.setState({ 
        _bPlay:false
      });
  }

  stopPlayAudio = () => {
    if(document.querySelector('#audio_play')){
      document.querySelector('#audio_play').pause();
    }

      this.setState({ 
        _bAudioPlay:false
      });
  }

  playAudio = () => {
    
    if(this.state.record)
      this.stopRecording();
    else{
      //document.audio.play();       

      

      if(document.querySelector('#rec_audio'))
        document.querySelector('#rec_audio').paused = true
      
      if(document.querySelector('#audio_play')){        
        document.querySelector('#audio_play').src = "http://tic1.engpool.com/upload/" + this.props.match.params.key1 + ".mp3";
        document.querySelector('#audio_play').play();
      } 

      this.setState({ 
        _bAudioPlay:true
      });
    }      

  }


  playRecording = () => {
    
    if(this.state.record)
      this.stopRecording();
    else{
      //document.audio.play();       
      this.playRecAudio();
      this.setState({ 
        _bPlay:true
      });
    }      

  }

  playRecAudio = () =>{
    let self = this;

    if(self.state._blob.size<50000)
    {
      alert('녹음파일이 작습니다. 다시 녹음해주세요');
      return;
    }
    
    setTimeout(function() {
      //self.setState({ record: false });    
      if(!self.state._blob) return;
        //self.replaceAudio(URL.createObjectURL(self.state._blob));     
        self.replaceAudio(self.state._blobURL);     
        
            //document.audio.currentTime = 0;
            document.querySelector('#audio_rec').currentTime = 0;
        
            //if(!document.audio.paused) return;
            if(!document.querySelector('#audio_rec').paused) return;
            setTimeout(function() {
                //if(!document.audio.paused) return;
               // if(!document.querySelector('#audio_rec').paused) return;
              //document.audio.play();
                //document.audio.play()
                self.setState({
                  _bPlay:true,
   
                })             
                self.audioEle1.play();
               
                
            }, 100);
            
            //document.audio.play();
            //self.audioEle1.play();
   
        }, 300);
      
    
  }

  replaceAudio=(src)=>{
    
    //var newAudio = document.querySelector('audio');
    var newAudio = document.querySelector('#audio_rec')
    //var newAudio = document.createElement('audio');
    /*
    newAudio.controls = true;
    newAudio.autoplay = true;
    newAudio.id = 'audio_rec';
    newAudio.name = 'audio_rec';   
    */
    //this.recAudioFullUrl = "https://tic1.engpool.com/upload/" + this.getFileName('mp3');
    //this.recAudioFullUrl = this.state._blobURL
    this.recAudioFullUrl = src;
    //this.recAudioFullUrl = this.playlist[4];

    if(src){
        newAudio.src = this.recAudioFullUrl;       
    }
    /*
    var parentNode = document.querySelector('audio').parentNode;
    parentNode.innerHTML = '';
    parentNode.appendChild(newAudio);
    */
    //document.audio = newAudio;
    //this.audioEle1 = document.audio;
    this.audioEle1 = newAudio
     /*
    this.setState({
      audioEle:document.audio
    })
    */

    //this.state.audioEle.play();
    
}
  
  onData(recordedBlob) {
    console.log('chunk of real-time data is: ', recordedBlob);    
  }
 
  onStop=(recordedBlob)=>{

    console.log('recordedBlob is: ', recordedBlob);
    this.state._blob = recordedBlob.blob;
    this.state._blobURL = recordedBlob.blobURL;

    //this.uploadFiles();
    
  }
    uploadFiles = () => {
        
    var blob = this.state._blob;
    var file = new File([this.state._blob], this.getFileName('mp3'), {
        type: 'audio/mp3'
    });

    //console.log('str='+src);


    //var options = new FileUploadOptions();
    
    //options.mimeType="multipart/form-data";
 
    var params = new Object();
    /*
    options.params = params;     
    var ft = new FileTransfer();
    console.log('upload start!!')
    */

    //spinnerplugin.show();

    var optionAxios = {
        headers: {
            //'Content-Type': 'application/x-www-form-urlencoded'
            'Content-Type': 'multipart/form-data'            
            //'Content-Type': 'access-control-request-method' 
            
        }
    }

    this.setState({
        dispSpinner:true
    })            

    let form = new FormData() 
    form.append('video-blob', file);
     
     axios.post("https://tic1.engpool.com/upload_kau.cshtml", form) 
     .then( response => { 
         console.log('response : ', JSON.stringify(response, null, 2)) 
         console.log('저장완료')

         alert('저장 완료했습니다..');
         this.setState({
            dispSpinner:false,
        })      

        window.close();
        
        }
    )
     .catch( error => { 
        alert('저장에 실패했습니다. 관리자에게 문의주셔요');
        
        return;
        console.log('failed', error)
     })

}

play1 = () => {
  //this.audioEle1.play()
  
  this.audioEle1.play()
  

  this.setState({
    audioStatus1: 'PLAYING'
  })
}


componentWillUnmount = () => {
  console.log('will unmount!!');
}

  render() {

    if(this.state.dispSpinner){
      return(
          <div style={{textAlign: 'center'}}>
      <Spinner animation='border' role='status'>
      <span className='sr-only'>Loading...</span>
      </Spinner>
      </div>
      )      
    }        

    const {params} = this.props.match;
    this.userno = params.key3;
    this.rec_file = params.key1;
    this.courseno = params.key2;
    this.qcode = params.key4;

    let a_id = this.state.audioEle1 ? this.state.audioEle1.src : "";
    let p_src = "";
          
    return (
    <div>

        <div>
        <AudioSpectrum
    id="audio-canvas1"
    height={110}
    width={this.props.width}
    audioId={'audio_play'}
    capColor={'red'}
    capHeight={2}
    meterWidth={10}
    meterCount={512}
    meterColor={[
      {stop: 1, color: 'blue'}
    ]}  
    gap={4}
  />
        </div>

        <div><audio id="audio_play" crossorigin="anonymous" preload="true" controls src={p_src}></audio></div>
        <div><Button id="btn-stop-recording" variant={this.state._bAudioPlay ?  'primary': 'default'} size="xm" onClick={this.playAudio} >Play</Button>        
        <Button id="btn-stop-recording" variant={!this.state._bAudioPlay ?  'default':'primary' } size="xm" onClick={this.stopPlayAudio} >Stop</Button>
        <Button id="btn-stop-recording" variant={!this.state._bAudioPlay ?  'default':'primary' } size="xm"><Link to='/correction/20210110204305_100004_0701010102_2/2/100004'>테스트</Link></Button><Button><Link to='/correction/20210110205131_100004_0701010102_3/1/100004'>테스트1</Link></Button>
        </div>

    {
      this.state._bPlay === false ? (
    <ReactMic
      record={this.state.record}
      width={this.props.width}
      className="sound-wave"
      onStop={this.onStop}
      onData={this.onData}
      strokeColor="#ff0000"
      backgroundColor="#ffffff"      
      visualSetting="frequencyBars"/>)
    : (<AudioSpectrum
    id="audio-canvas"
    height={110}
    width={this.props.width}
    audioId={'audio_rec'}
    capColor={'red'}
    capHeight={2}
    meterWidth={10}
    meterCount={512}
    meterColor={[
      {stop: 1, color: 'blue'}
    ]}  
    gap={4}
  />)}

      <div>  
        <Button id="btn-start-recording" variant={this.state._nRecCount>2 ?  'default': (this.state._bPlay ? 'danger' : (this.state.record?  'default': (this.state._bListenTime ? 'default' :'default')))}  size="xm" onClick={this.startRecording} >{this.state.record ? "Record" : 'Record'}</Button>
        <Button id="btn-stop-recording" variant={this.state.record ?  'primary': (this.state._bPlay ?  'primary': (this.state._bListenTime ? 'default' :'default'))} size="xm" onClick={this.playRecording} >{this.state._bPlay ? 'Playing': 'Play'}</Button>        
        <Button id="btn-stop-recording" variant={!this.state.record ?  'default':'primary' } size="xm" onClick={this.stopRecording} >Stop</Button>
        <Button id="btn-upload-recording" variant="primary" size="xm" onClick={this.uploadFiles} >제출하기</Button>
        {
      /*
      <Button id="btn-stop-recording" variant={!this.state.record ?  'default':'primary' } size="xm" onClick={this.stopRecording} >Stop</Button>
      
       <audio id="audio-element"  preload="auto" 
               src={`${this.playlist[4]}`} controls 
              
              ></audio>
        */}
      </div>
        
      <div><audio id="audio_rec" crossorigin="anonymous" preload="true" controls src={a_id}></audio>
      
</div>  
      
         
     
      
  
    </div>  
    );
  
  {/*
  else{
    return (
     
      <div>
        <ReactMic
          record={this.state.record}
          width={this.props.width}
          className="sound-wave"
          onStop={this.onStop}
          onData={this.onData}
          strokeColor="#000000"
          backgroundColor="#adaeb3" />
        
        <div>
        <Button id="btn-start-recording" variant={this.state.record ?  'danger':'primary' }  size="xm" onClick={this.startRecording} >Start</Button>
        <Button id="btn-stop-recording" variant={this.state._bPlay ?  'danger':'primary' } size="xm" onClick={this.playRecording} >Play</Button>
        <Button id="btn-stop-recording" variant={!this.state.record ?  'danger':'primary' } size="xm" onClick={this.stopRecording} >Stop</Button>

      <Button id="btn-upload-recording" variant="primary" size="lg"onClick={this.uploadFiles} >Upload</Button>
        </div>
        <div><audio controls autoPlay ></audio></div>

    
        
        </div>  
        
    );
    
  }
  */}
  }
}export default ReactMicCom;