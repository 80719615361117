import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import ReactMicCom from './components/ReactMic';


function App() {
  return (
   <div className="App">     
      <Router>
        <Switch>
          <Route path='/correction/:key1/:key2/:key3/:key4' component={ReactMicCom}/>
        </Switch>
      </Router>
      
    </div>

  );
}
export default App;
